.loader {
    display: flex;
    justify-content: center;
    height: calc(100dvh - 160px);
    padding-top: 80px;
}

.items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin-bottom: 140px;
}

.item {
    background: var(--secondary-background);
    border-radius: 20px;
    box-shadow: 0 4px 24px rgba(53, 51, 57, .08);
    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.item__info {
    padding: 0 8px 12px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.item__name {
    margin: 12px 0 10px;
    font-size: 12px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    white-space: normal;
    word-wrap: break-word;
}

.img__wrapper {
    width: 100%;
    padding-top: 100%;
    position: relative;
    overflow: hidden;
    margin: 0;
}

.item__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
    background: #d6d6d6;
}

.item__out {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
    background: var(--product-out);
}

.item__count {
    font-size: 12px;
    display: flex;
    align-items: center;
}

.item__img.loaded {
    width: 172px;
    height: 172px;
    background-color: transparent;
}

.item__price {
    padding-bottom: 8px;
}

.item__btns {
    display: flex;
    width: 100%;
    justify-content: end;
    justify-content: space-between;
    gap: 10px;
}

.item__btn {
    border: none;
    padding: 8px 4.5px;
    font-size: 12px;
    border-radius: 15px;
    padding: 8px 12px;
    width: 100%;
    background: var(--button-background);
    color: var(--light-text-color);
}

@media (max-width: 390px) {
    .item__btn {
        font-size: 12px;
    }
}

.count__out {
    background: var(--product-out);
}