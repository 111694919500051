.item {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: scroll;
    background: #fff;
    z-index: 222;
}

.img__wrapper {
    width: 100%;
    padding-top: 100%;
    position: relative;
    overflow: hidden;
    margin: 0;
}

.item__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: #d6d6d6;
}

.item__name,
.related__item__title {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    z-index: 1;
    word-wrap: break-word;
}

.related__item__title {
    margin-bottom: 15px;
}

.item__counter {
    display: flex;
    z-index: 89;
    gap: 10px;
}

.item__check {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--border-color);
}

.item__description {
    word-wrap: break-word;
    text-align: justify;
}

.counter {
    display: flex;
    gap: 10px;
}

.loader {
    width: 100%;
    height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item__info {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 100px;
    border-radius: 20px 20px 0px 0px;
    background-color: var(--secondary-background);
    position: relative;
    margin-top: -40px;
    border-top: 1px solid var(--border-color);
}

.item__counter {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    padding: 16px;
    position: fixed;
    width: 100%;
    bottom: -1px;
    background-color: var(--secondary-background);
    box-shadow: 0 -4px 24px rgba(0, 0, 0, .06);
    z-index: 89;
}

.item__btns {
    flex: 65%;
}

.item__text {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--border-color);
}

.item__text__none__border {
    padding-bottom: 15px;
}

.item__btn,
.no__btn {
    position: relative;
    width: 100%;
    border: none;
    font-size: 14px;
    height: 100%;
    line-height: 20px;
    padding: 15px 17px;
    background: var(--button-background);
    color: var(--light-text-color);
    font-weight: 700;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
}

.no__btn {
    justify-content: center;
}

.counter {
    display: flex;
    gap: 10px;
    flex: 35%;
    background-color: var(--page-background);
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-radius: 12px;
}

.counter__count {
    font-weight: 600;
}

.counter__icon {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: all 0.3s ease;
}

.item__close {
    background-color: var(--secondary-background);
    color: var(--text-color);
    border-radius: 50%;
    position: absolute;
    padding: 5px;
    top: 10px;
    left: 10px;
    width: 30px;
    height: 30px;
    z-index: 200;
}

.counter__btn {
    font-size: 24px;
    border: none;
    background-color: var(--page-background);
}

.counter__icon__none {
    color: #adadad;
    transition: all 0.3s ease;
}

.switch {
    width: 55px;
    height: 30px;
    border: 1px solid var(--switch-border-color);
    display: flex;
    justify-content: flex-start;
    border-radius: 50px;
    padding: 5px;
    cursor: pointer;
}

.switch[data-is-on="true"] {
    justify-content: flex-end;
}

.handle {
    width: 18px;
    height: 18px;
    background-color: var(--handle-background);
    border-radius: 40px;
}

.item__discount {
    text-decoration: line-through;
    font-size: 12px;
    position: absolute;
    top: 3px;
    right: 10px;
}

.item__price {
    display: flex;
    gap: 10px;
    font-size: 16px;
}

.item__sum {
    padding-right: 10px;
}

.related {
    padding-top: 10px;
    border-top: 1px solid var(--border-color);
}

.related__none__border {
    border: none;
    padding: 0;
}

.related__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin-bottom: 90px;
}

.related__list__none {
    grid-template-columns: repeat(1, 1fr);
}

.related__item {
    background: var(--secondary-background);
    border-radius: 20px;
    box-shadow: 0 4px 24px rgba(53, 51, 57, .08);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.related__img__wrapper {
    width: 100%;
    padding-top: 100%;
    position: relative;
    overflow: hidden;
    margin: 0;
}

.related__item__img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
    background: #d6d6d6;
}

.related__item__info {
    padding: 0 8px 12px;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.related__item__name {
    margin: 12px 0 10px;
    font-size: 12px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    white-space: normal;
}

.related__item__btns {
    display: flex;
    width: 100%;
    justify-content: end;
    justify-content: space-between;
    gap: 10px;
}

.related__item__btn {
    border: none;
    background: var(--button-text-color);
    padding: 8px 4.5px;
    font-size: 12px;
    border-radius: 15px;
    padding: 8px 12px;
    width: 100%;
}

.related__none {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
}

.related__item__none {
    text-align: center;
    color: #000;
    font-weight: 400;
    font-size: 17px;
    padding: 10px;
    border-radius: 12px;
    border: 1px solid var(--border-color);
}

.loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    background-color: #fff;
    z-index: 999999;
}

.loader__text {
    font-size: 20px;
    font-weight: 500;
}