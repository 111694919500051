.payment__section {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: scroll;
    z-index: 99999;
    background-color: var(--secondary-background);
}

.payment {
    padding: 16px;
    margin-bottom: 190px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.title {
    font-weight: 600;
    padding-top: 20px;
    font-size: 24px;
    padding-bottom: 20px;
    font-family: 'Montserrat', sans-serif;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 10px;
    margin-left: 15px;
    margin-right: 15px;
}

.field {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.label {
    font-size: 15px;
}

.input {
    padding: 10px;
    border-radius: 10px;
    border: none;
    border: 1px solid var(--border-color);
    outline: none;
}

.textarea {
    padding: 10px;
    border-radius: 10px;
    border: 1px solid var(--border-color);
    outline: none;
    max-width: 100%;
    height: 100px;
    font-family: 'Roboto', sans-serif;
}

.footer__btn {
    background-color: var(--payment-background);
    border: none;
    border-radius: 12px;
    color: var(--light-text-color);
    font-weight: 600;
    height: 50px;
    display: flex;
    padding: 20px;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 15px;
    width: 100%;
    background-color: var(--secondary-background);
    box-shadow: 0 -4px 24px rgba(0, 0, 0, .06);
}

.rules {
    font-size: 10px;
    color: #000;
    padding-top: 12px;
    text-align: justify;
}

.link {
    text-decoration: underline;
    color: #0000FF;
    cursor: pointer;
}

.buttons {
    display: flex;
    gap: 10px;
    justify-content: space-between;
}

.button,
.activeButton {
    padding: 10px;
    font-size: 14px;
    border: none;
    width: 100%;
    border-radius: 10px;
    color: #000;
}

.activeButton {
    background-color: var(--payment-background);
    color: var(--light-text-color);
}

.error {
    color: red;
}

.error__input {
    border: 1px solid red;
}

.loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    background-color: #fff;
    z-index: 999999;
}

.loader__text {
    font-size: 20px;
    font-weight: 500;
}