.title {
    font-size: 20px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 20px;
}

.admin__change {
    display: flex;
    flex-direction: column;
}

.field {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.input {
    border: 1px solid var(--border-color);
    padding: 10px;
    border-radius: 12px;
    outline: none;
}

.btn {
    border-radius: 12px;
    border: none;
    width: 100%;
    color: var(--light-text-color);
    font-weight: 600;
    background-color: var(--button-background);
    height: 50px;
}

.loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    background-color: #fff;
    z-index: 999999;
}

.loader__text {
    font-size: 20px;
    font-weight: 500;
}

.delete__btn {
    border-radius: 12px;
    padding: 10px;
    border: none;
    width: 100%;
    height: 50px;
    background-color: var(--delete-button-color);
    color: var(--button-background);
    font-weight: 600;
}

.field {
    position: relative;
    margin-bottom: 15px;
}

.input[type="file"] {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
}

.custom__file__upload {
    text-align: center;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 12px;
}

.item__picture {
    width: 100%;
    padding-top: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 12px;
}

.item__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
}

.error {
    color: red;
    text-align: center;
    font-size: 14px;
}

.error__input {
    border: 1px solid red;
}

.error__input::placeholder {
    color: red;
}

.buttons {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    background: #fff;
    z-index: 99999;
    padding: 16px;
}

.file__name {
    word-wrap: break-word;
}